<template>
  <div class="container">
    <!-- <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="医护管理"
        :breadcrumb="{ props: { routes } }"
      />
    </div> -->
    <div v-if="getJurisdictionList.indexOf('healthcare-checkList') > -1">
      <ant-tabel
        :data="data"
        :columns="columns"
        :loading="loading"
        :pagination="pagination"
        @changePaging="changePaging"
      ></ant-tabel>
    </div>
  </div>
</template>
<script>
import api from '@/api/index.js';
import AntTabel from '../Ant-tabel/Ant-tabel.vue';
import storage from '../../storage/storage.js';
const columns = [
  {
    title: 'ID',
    dataIndex: 'num',
    key: 'num',
    width: 90,
    align: 'center',
    scopedSlots: { customRender: 'num' },
  },
  {
    title: '头像',
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
    width: 120,
    scopedSlots: { customRender: 'platform' },
  },
  { title: '昵称', dataIndex: 'nickName', key: 'nickName', width: 250 },
  { title: '真实姓名', dataIndex: 'realName', key: 'realName' },
  { title: '手机号', dataIndex: 'mobilePhoneNumber', key: 'mobilePhoneNumber' },
  { title: '医院', dataIndex: 'hospital.name', key: 'hospital.name' },
  // { title: 'ICU科室', dataIndex: 'icuDepartment', key: 'icuDepartment' },
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      loading: true,
      data,
      columns,
      routes: [
        {
          path: 'index',
          breadcrumbName: 'Health-Care management',
        },
      ],
      getJurisdictionList: '',
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  mounted() {
    this.Healthcare();
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem('getJurisdiction');
    },
    Healthcare() {
      this.data = [];
      api.healthcare(this.pagination).then((res) => {
        this.data = res.userList;
        this.pagination.total = res.count;
        this.loading = false;
      });
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.Healthcare();
    },
  },
  watch: {},
};
</script>
<style scoped>
</style>
